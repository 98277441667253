'use client';

import { useRouter } from 'next/navigation';
import { MouseEventHandler, useCallback } from 'react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useAmplitudeContext } from '@/utils/amplitude';
import { useSupabase } from '@/utils/supabase/client';
export const LoginByGoogleBtn: React.FC<{
  next?: string | null;
  className?: string;
}> = ({
  next,
  className
}) => {
  const supabase = useSupabase();
  const {
    trackAmplitudeEvent
  } = useAmplitudeContext();
  const router = useRouter();
  const handleLoginByGoogle: MouseEventHandler<HTMLButtonElement> = useCallback(async e => {
    e.preventDefault();
    const redirectToURL = new URL(`${window.location.origin}/auth/callback`);
    if (next) {
      redirectToURL.searchParams.set('next', next);
    }
    try {
      const {
        data,
        error
      } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: redirectToURL.toString()
        }
      });
      if (error) throw error;
    } catch (error) {
      console.error('Error during Google sign-in:', error);
      // 处理错误，例如显示错误消息
    }
  }, [supabase, next, trackAmplitudeEvent]);
  return <Button className={cn('w-full', className)} variant="outline" onClick={handleLoginByGoogle} data-sentry-element="Button" data-sentry-component="LoginByGoogleBtn" data-sentry-source-file="google.tsx">
      <svg className="mr-2 h-4 w-4" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512" data-sentry-element="svg" data-sentry-source-file="google.tsx">
        <path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" data-sentry-element="path" data-sentry-source-file="google.tsx"></path>
      </svg>
      Continue with Google
    </Button>;
};