'use client';

import { useSearchParams } from 'next/navigation';
import { Suspense } from 'react';
import { Background } from '@/components/Background';
import { LoginByEmailForm } from '@/components/login/email';
import { LoginByGithubBtn } from '@/components/login/github';
import { LoginByGoogleBtn } from '@/components/login/google';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { AppEnv, PUBLIC_APP_ENV } from '@/utils/constants';
const LoginForm: React.FC = () => {
  const searchParams = useSearchParams();
  const next = searchParams.get('next');
  return <>
      {PUBLIC_APP_ENV !== AppEnv.PRODUCTION && <LoginByEmailForm next={next} />}

      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <Separator className="w-full" data-sentry-element="Separator" data-sentry-source-file="page.tsx" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">Or</span>
        </div>
      </div>

      <div className="space-y-2">
        {PUBLIC_APP_ENV === AppEnv.PRODUCTION && <LoginByGoogleBtn next={next} />}
        <LoginByGithubBtn next={next} data-sentry-element="LoginByGithubBtn" data-sentry-source-file="page.tsx" />
      </div>
    </>;
};
export default function LoginPage() {
  return <Suspense data-sentry-element="Suspense" data-sentry-component="LoginPage" data-sentry-source-file="page.tsx">
      <Background data-sentry-element="Background" data-sentry-source-file="page.tsx" />
      <div className="min-h-screen flex items-center justify-center p-4">
        <Card className="w-full max-w-md shadow-2xl rounded-lg" data-sentry-element="Card" data-sentry-source-file="page.tsx">
          <CardHeader className="space-y-1" data-sentry-element="CardHeader" data-sentry-source-file="page.tsx">
            <CardTitle className="text-3xl font-bold text-center" data-sentry-element="CardTitle" data-sentry-source-file="page.tsx">Welcome to CreateAny</CardTitle>
            <CardDescription className="text-center" data-sentry-element="CardDescription" data-sentry-source-file="page.tsx">
              choose your preferred login method
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4" data-sentry-element="CardContent" data-sentry-source-file="page.tsx">
            <div className="space-y-2">
              <LoginForm data-sentry-element="LoginForm" data-sentry-source-file="page.tsx" />
            </div>
          </CardContent>
          <CardFooter data-sentry-element="CardFooter" data-sentry-source-file="page.tsx">
            <p className="text-xs text-center w-full text-gray-600">
              By clicking continue, you agree to our{' '}
              <a href="#" className="underline hover:text-primary">
                Terms of Service
              </a>{' '}
              and{' '}
              <a href="#" className="underline hover:text-primary">
                Privacy Policy
              </a>
              .
            </p>
          </CardFooter>
        </Card>
      </div>
    </Suspense>;
}